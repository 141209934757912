import React, { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import Confetti from "react-confetti";
import Header from "../components/Header";
import HowToPlay from "../components/HowToPlay";

const ClassicGame = () => {
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [guess, setGuess] = useState("");
  const [dailyPlayer, setDailyPlayer] = useState(null);
  const [gameWon, setGameWon] = useState(false);
  const [confettiRunning, setConfettiRunning] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [timeLeft, setTimeLeft] = useState(""); // Countdown timer
  const [streak, setStreak] = useState(0); // User's streak count
  const [arrowSrc, setArrowSrc] = useState("/arrow-right.png");

  const cardRef = useRef(null);

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5001";

  // Generate or fetch session ID
  const getSessionId = () => {
    let sessionId = localStorage.getItem("session_id");
    if (!sessionId) {
      sessionId = crypto.randomUUID(); // Generate a new session ID
      localStorage.setItem("session_id", sessionId);
    }
    return sessionId;
  };

  const sessionId = getSessionId();

  // Calculate time left until midnight
  const calculateTimeLeft = () => {
    const now = new Date();
    const midnight = new Date(now);
    midnight.setHours(24, 0, 0, 0); // Set to midnight
    const difference = midnight - now;

    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    const savedGuesses = JSON.parse(localStorage.getItem("guesses")) || [];
    const savedGameWon = localStorage.getItem("gameWon") === "true";

    setGuesses(savedGuesses);
    if (savedGameWon) {
      setGameWon(true);
    }
  }, []);

  // Streak management
  useEffect(() => {
    // Initialize streak from localStorage
    const savedStreak = parseInt(localStorage.getItem("streak") || "0", 10);
    setStreak(savedStreak);
  }, []);

  // Handle streak management
  useEffect(() => {
    if (gameWon) {
      const today = new Date().toISOString().split("T")[0];
      const lastPlayedDate = localStorage.getItem("lastPlayedDate");
      let currentStreak = parseInt(localStorage.getItem("streak") || "0", 10);

      console.log("Today:", today);
      console.log("Last Played Date:", lastPlayedDate);
      console.log("Current Streak Before Update:", currentStreak);

      if (lastPlayedDate === today) {
        console.log("Streak for today already updated.");
        return;
      }

      if (!lastPlayedDate || lastPlayedDate !== today) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayString = yesterday.toISOString().split("T")[0];

        if (lastPlayedDate === yesterdayString) {
          currentStreak += 1;
          console.log("Incrementing streak:", currentStreak);
        } else {
          currentStreak = 1;
          console.log("Resetting streak to:", currentStreak);
        }

        localStorage.setItem("streak", currentStreak);
        localStorage.setItem("lastPlayedDate", today);
      }

      setStreak(currentStreak);
      console.log("Updated Streak:", currentStreak);
    }
  }, [gameWon]);

  const getConference = (teamAbbrev) => {
    const eastTeams = [
      "BOS",
      "BUF",
      "CAR",
      "CBJ",
      "DET",
      "FLA",
      "MTL",
      "NJD",
      "NYI",
      "NYR",
      "OTT",
      "PHI",
      "PIT",
      "TBL",
      "TOR",
      "WSH",
    ];
    const westTeams = [
      "ANA",
      "ARI",
      "CGY",
      "CHI",
      "COL",
      "DAL",
      "EDM",
      "LAK",
      "MIN",
      "NSH",
      "SEA",
      "SJS",
      "STL",
      "VAN",
      "VGK",
      "WPG",
    ];

    if (eastTeams.includes(teamAbbrev)) return "East";
    if (westTeams.includes(teamAbbrev)) return "West";
    return "Unknown";
  };

  // Save guesses to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("guesses", JSON.stringify(guesses));
  }, [guesses]);

  // Save gameWon state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("gameWon", gameWon);
  }, [gameWon]);

  // Update timer every second
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Reset for the next day
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const savedDate = localStorage.getItem("lastPlayedDate");

    if (savedDate !== today) {
      setGuesses([]);
      setGameWon(false);
    }
  }, []);

  // Fetch all players data
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/players`);
        if (!response.ok) {
          throw new Error("Failed to fetch players");
        }
        const data = await response.json();

        // Map database fields to match expected structure and calculate conference
        const mappedPlayers = data.map((player) => {
          const conference = getConference(player.current_team_abbrev);

          return {
            ...player,
            fullName: player.full_name,
            teamLogo: player.current_team_logo,
            sweaterNumber: player.sweater_number,
            conference: conference, // Add precomputed conference here
            currentTeam: {
              fullName: player.current_team_full_name,
              abbrev: player.current_team_abbrev,
              logo: player.current_team_logo,
            },
            draftDetails: {
              overallPick: player.draft_overall_pick || 0, // Treat N/A as 0
            },
            careerTotals: {
              regularSeason: {
                points: player.career_regular_season_points || 0,
                goals: player.career_regular_season_goals || 0,
                assists: player.career_regular_season_assists || 0,
              },
              playoffs: {
                points: player.career_playoffs_points || 0,
                goals: player.career_playoffs_goals || 0,
                assists: player.career_playoffs_assists || 0,
              },
            },
            birthInfo: {
              date: player.birth_date,
              city: player.birth_city,
              stateProvince: player.birth_state_province,
              country: player.birth_country,
            },
            weight: {
              pounds: player.weight_pounds,
              kilograms: player.weight_kilograms,
            },
            height: {
              inches: player.height_inches,
              centimeters: player.height_centimeters,
            },
            headshot: player.headshot_url,
          };
        });

        setPlayers(mappedPlayers);
      } catch (error) {
        console.error("Error fetching players:", error.message);
      }
    };
    fetchPlayers();
  }, []);

  useEffect(() => {
    if (dailyPlayer) {
      localStorage.setItem(
        `guesses_${dailyPlayer.player_id}`,
        JSON.stringify(guesses)
      );
    }
  }, [guesses, dailyPlayer]);

  // Fetch the daily player
  useEffect(() => {
    const fetchDailyPlayer = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/daily-player`);
        if (!response.ok) {
          throw new Error("Failed to fetch daily player");
        }
        const data = await response.json();

        // Calculate the conference for the daily player
        const conference = getConference(data.current_team_abbrev);

        // Include conference in daily player data
        const dailyPlayerWithConference = {
          ...data,
          conference: conference,
        };

        setDailyPlayer(dailyPlayerWithConference);

        // Load guesses for the current daily player
        const savedGuesses =
          JSON.parse(localStorage.getItem(`guesses_${data.player_id}`)) || [];
        setGuesses(savedGuesses);

        // Restore gameWon state from localStorage
        const savedGameWon = localStorage.getItem("gameWon") === "true";
        if (savedGameWon) {
          setGameWon(true);
        }
      } catch (error) {
        console.error("Error fetching daily player:", error.message);
      }
    };
    fetchDailyPlayer();
  }, []);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }

    return age;
  };

  const getBackgroundColor = (value, dailyValue, type) => {
    // Normalize "N/A", null, or undefined to 0 for numeric comparisons
    const normalizedValue = value === "N/A" || value == null ? 0 : value;
    const normalizedDailyValue =
      dailyValue === "N/A" || dailyValue == null ? 0 : dailyValue;

    // Check for exact match
    if (normalizedValue === normalizedDailyValue) {
      return "bg-green-500 text-white"; // Exact match
    }

    const isClose = (a, b, range) => Math.abs(a - b) <= range;

    // Handle numbers
    if (type === "number") {
      if (isClose(normalizedValue, normalizedDailyValue, 5)) {
        return "bg-yellow-500 text-black"; // Close match
      } else {
        return "bg-red-500 text-white"; // Incorrect match
      }
    }

    // Handle strings
    if (type === "string") {
      return "bg-red-500 text-white"; // Incorrect string
    }

    // Default for unexpected types
    return "bg-gray-500 text-white";
  };

  const handleInputChange = (e) => {
    const input = e.target.value.toLowerCase();
    setGuess(input);

    if (input) {
      // Filter players based on the `fullName` property
      const filtered = players
        .filter((player) => player.fullName.toLowerCase().includes(input))
        .slice(0, 20); // Limit results to the top 20
      setFilteredPlayers(filtered);
    } else {
      setFilteredPlayers([]);
    }
  };

  const handleSelectPlayer = (player) => {
    setGuess(player.fullName); // Use the `fullName` property
    setFilteredPlayers([]); // Clear the filtered players list
  };

  const handleSubmit = async () => {
    const selectedPlayer = players.find(
      (player) => player.fullName.toLowerCase() === guess.toLowerCase()
    );

    if (!selectedPlayer) {
      alert("Player not found. Please select a valid player.");
      return;
    }

    const guessedAge = calculateAge(selectedPlayer.birthInfo.date);
    const dailyAge = calculateAge(dailyPlayer.birth_date);

    const feedback = {
      name: selectedPlayer.fullName,
      team: selectedPlayer.currentTeam.fullName,
      teamLogo: selectedPlayer.currentTeam.logo,
      sweaterNumber: selectedPlayer.sweaterNumber,
      position: selectedPlayer.position,
      conference: selectedPlayer.conference, // Use precomputed conference
      country: selectedPlayer.birthInfo.country, // Correct access
      age: guessedAge,
      overallPick: selectedPlayer.draftDetails.overallPick,
      points: selectedPlayer.careerTotals.regularSeason.points,
      headshot: selectedPlayer.headshot,
      teamColor: getBackgroundColor(
        selectedPlayer.currentTeam.fullName,
        dailyPlayer.current_team_full_name,
        "string"
      ),
      sweaterColor: getBackgroundColor(
        selectedPlayer.sweaterNumber,
        dailyPlayer.sweater_number,
        "number"
      ),
      positionColor: getBackgroundColor(
        selectedPlayer.position,
        dailyPlayer.position,
        "string"
      ),
      conferenceColor: getBackgroundColor(
        selectedPlayer.conference,
        dailyPlayer.conference, // Use precomputed conference
        "string"
      ),
      countryColor: getBackgroundColor(
        selectedPlayer.birthInfo.country, // Correct comparison
        dailyPlayer.birth_country, // Correct comparison
        "string"
      ),
      ageColor: getBackgroundColor(guessedAge, dailyAge, "number"),
      overallPickColor: getBackgroundColor(
        selectedPlayer.draftDetails.overallPick,
        dailyPlayer.draft_overall_pick,
        "number"
      ),
      pointsColor: getBackgroundColor(
        selectedPlayer.careerTotals.regularSeason.points,
        dailyPlayer.career_regular_season_points,
        "number"
      ),
    };

    setGuesses((prev) => [...prev, { ...feedback, visibleCategories: [] }]);

    const revealCategories = [
      "headshot",
      "teamLogo",
      "sweaterNumber",
      "position",
      "conference",
      "country",
      "age",
      "overallPick",
      "careerPoints",
    ];

    revealCategories.forEach((category, index) => {
      setTimeout(() => {
        setGuesses((prev) =>
          prev.map((g, guessIndex) =>
            guessIndex === prev.length - 1
              ? {
                  ...g,
                  visibleCategories: [...g.visibleCategories, category],
                }
              : g
          )
        );
      }, index * 400);
    });

    if (selectedPlayer.fullName === dailyPlayer.full_name) {
      setGameWon(true);
      localStorage.setItem("gameWon", true);
      setConfettiRunning(true);

      setTimeout(() => setConfettiRunning(false), 7000);
      setTimeout(() => {
        cardRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }

    setGuess("");
  };

  return (
    <div
      className="fixed inset-0 bg-cover bg-center"
      style={{
        backgroundImage:
          "linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1)), url('/background.jpg')",
      }}
    >
      <Header />

      {confettiRunning && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}

      <div className="relative max-w-4xl mx-auto h-screen overflow-y-auto rounded-lg px-4 pt-10 pb-56">
        <h1 className="text-4xl font-bold mb-8 text-center text-white">
          Classic Mode
        </h1>

        <div className="flex flex-col md:flex-row justify-center items-start gap-4 mb-8 w-5/6 mx-auto">
          {/* Countdown Box */}
          <div className="flex-1 w-full bg-black bg-opacity-70 rounded-lg shadow-md">
            <div className="relative text-white p-4 text-center">
              <h2 className="text-lg font-bold">Guess the Player of the Day</h2>
              <p className="text-base mt-2">
                Time left until the next player: <strong>{timeLeft}</strong>
              </p>
            </div>
          </div>
        </div>

        {/* Input and Controls */}
        <div className="flex w-full sm:w-5/6 items-center space-x-2 mb-4 mx-auto">
          <div className="relative flex-grow">
            <input
              type="text"
              value={guess}
              onChange={handleInputChange}
              placeholder={
                gameWon
                  ? "You found the player! Wait for the next one."
                  : "Start typing a player's name"
              }
              className={`w-full h-12 p-3 pr-12 rounded-lg text-lg ${
                gameWon
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-white text-black"
              }`}
              disabled={gameWon} // Disable input if game is won
            />
            {/* Arrow Icon */}
            {!gameWon && (
              <button
                onClick={handleSubmit}
                onMouseEnter={() => setArrowSrc("/arrow-right-blue.png")}
                onMouseLeave={() => setArrowSrc("/arrow-right.png")}
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
              >
                <img src={arrowSrc} alt="Submit" className="w-8 h-8" />
              </button>
            )}

            {filteredPlayers.length > 0 && (
              <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-lg mt-2 max-h-60 overflow-y-auto z-20">
                {filteredPlayers.map((player) => (
                  <li
                    key={player.player_id}
                    onClick={() => handleSelectPlayer(player)}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200 flex items-center text-black"
                  >
                    <img
                      src={player.headshot}
                      alt={player.fullName}
                      className="w-8 h-8 rounded-full mr-3"
                    />
                    <span>{player.fullName}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="bg-gray-800 bg-opacity-90 flex items-center justify-between h-12 w-40 rounded-md shadow-md px-2">
            {/* How to Play Button */}
            <div className="flex items-center justify-center h-full w-1/2">
              <HowToPlay />
            </div>

            {/* Divider */}
            <div className="h-full w-px bg-gray-600"></div>

            {/* Streak Button */}
            <div className="flex items-center justify-center h-full w-1/2 relative">
              <div className="relative">
                <img
                  src="/fire.png"
                  alt="Streak"
                  className="w-10 h-10 opacity-80"
                />
                <span className="text-white text-2xl font-bold absolute inset-0 flex items-center justify-center">
                  {streak}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Combined Categories and Guesses */}
        <div className="overflow-x-auto">
          <div className="space-y-2 w-max mx-auto">
            {/* Categories Row */}
            <div className="grid grid-cols-9 gap-2 text-white text-center p-1 font-bold">
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Player
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Team
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Conference
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Sweater
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Position
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Birth Country
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Age
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Overall Pick
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Career Points
              </div>
            </div>

            {/* Guesses */}
            {[...guesses].reverse().map((guess, rowIndex) => (
              <div
                key={rowIndex}
                className="grid grid-cols-9 gap-2 bg-gray-900 bg-opacity-60 p-2 rounded-lg"
              >
                {/* Player */}
                {guess.visibleCategories.includes("headshot") && (
                  <div className="relative flex justify-center items-center text-center w-20 h-20 rounded-lg">
                    <img
                      src={guess.headshot}
                      alt={guess.name}
                      className="w-20 h-20 rounded-full"
                    />
                  </div>
                )}

                {/* Team */}
                {guess.visibleCategories.includes("teamLogo") && (
                  <div
                    className={`relative flex justify-center items-center text-center w-20 h-20 rounded-lg ${guess.teamColor}`}
                  >
                    <img
                      src={guess.teamLogo}
                      alt={`${guess.team} logo`}
                      className="w-16 h-16 rounded"
                    />
                  </div>
                )}

                {guess.visibleCategories.includes("conference") && (
                  <div
                    className={`relative flex justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.conferenceColor}`}
                  >
                    <span className="z-10 text-2xl">{guess.conference}</span>
                  </div>
                )}

                {/* Sweater */}
                {guess.visibleCategories.includes("sweaterNumber") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.sweaterColor}`}
                  >
                    <span className="z-10 text-2xl">{guess.sweaterNumber}</span>
                    {guess.sweaterNumber > dailyPlayer.sweater_number && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.sweaterNumber < dailyPlayer.sweater_number && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}

                {/* Position */}
                {guess.visibleCategories.includes("position") && (
                  <div
                    className={`relative flex justify-center items-center text-center w-20 h-20 rounded-lg text-2xl font-bold ${guess.positionColor}`}
                  >
                    {guess.position}
                  </div>
                )}

                {/* Country */}
                {guess.visibleCategories.includes("country") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.countryColor}`}
                  >
                    <span className="z-10 text-2xl">{guess.country}</span>
                  </div>
                )}

                {/* Age */}
                {guess.visibleCategories.includes("age") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.ageColor}`}
                  >
                    <span className="z-10 text-2xl">{guess.age}</span>
                    {guess.age > calculateAge(dailyPlayer.birth_date) && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.age < calculateAge(dailyPlayer.birth_date) && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}

                {/* Overall Pick */}
                {guess.visibleCategories.includes("overallPick") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.overallPickColor}`}
                  >
                    <span className="z-10 text-2xl">
                      {guess.overallPick || "N/A"}
                    </span>
                    {guess.overallPick > dailyPlayer.draft_overall_pick && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.overallPick < dailyPlayer.draft_overall_pick && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}

                {/* Career Points */}
                {guess.visibleCategories.includes("careerPoints") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.pointsColor}`}
                  >
                    <span className="z-10 text-2xl">
                      {guess.points || "N/A"}
                    </span>
                    {guess.points >
                      dailyPlayer.career_regular_season_points && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.points <
                      dailyPlayer.career_regular_season_points && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Scroll Hint */}
        <div className="block md:hidden text-center text-white mt-4">
          <p className="text-sm">
            Swipe or scroll to the right to view more details!
          </p>
        </div>

        {/* Game Won Message */}
        {gameWon && (
          <div
            ref={cardRef}
            className="bg-green-500 text-white text-center p-5 rounded-lg mt-8 max-w-md mx-auto"
          >
            <h2 className="text-3xl font-bold mb-4">Congratulations!</h2>
            <p className="text-lg">
              You guessed{" "}
              <strong>
                {dailyPlayer?.first_name} {dailyPlayer?.last_name}
              </strong>{" "}
              correctly!
            </p>
            <p className="text-lg mt-2">
              This player is for the day:{" "}
              <strong>
                {DateTime.fromISO(dailyPlayer?.date, { zone: "utc" })
                  .setZone("America/New_York")
                  .toFormat("yyyy-MM-dd")}
              </strong>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassicGame;
