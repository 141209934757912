import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ClassicGame from './pages/ClassicGame'; // Import Classic mode page
import StatsGame from './pages/StatsGame'; // Placeholder for Stats mode
import TeamsGame from './pages/TeamsGame'; // Placeholder for Teams mode

function App() {
    return (
        <Router>
            <div
                className="relative min-h-screen bg-cover bg-center"
                style={{ backgroundImage: "url('/background.jpg')" }}
            >
                {/* Overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-60"></div>

                {/* Main Content */}
                <div className="relative z-10 flex flex-col items-center justify-center min-h-screen text-white">
                    <Routes>
                        <Route path="/" element={<ClassicGame />} />
                        {/* <Route path="/classic" element={<ClassicGame />} />
                        <Route path="/stats" element={<StatsGame />} />
                        <Route path="/teams" element={<TeamsGame />} /> */}
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
