import React from "react";

const Header = () => {
  return (
    <header className="top-0 left-0 w-full bg-transparentz-50">
      <div className="flex justify-center">
        <img
          src="/nhldlelogo.png"
          alt="NHLDle Logo"
          className="h-36"
        />
      </div>
    </header>
  );
};

export default Header;
